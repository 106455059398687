import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { SliceZone } from '@prismicio/react'
import { Layout } from '../../components/Layout'
import { components } from '../../slices'

function BlogItem(props) {
  console.log('BlogItem props: ', props)
  const { prismicBlogItem, prismicBlog } = props.data
  const recipesItem = prismicBlogItem.data
  // const eventInfoSlice = event.body.find(item => item.slice_type === 'event_info');
  const seo = {
    title: prismicBlogItem.data.seo_title || prismicBlogItem.data.title.text,
    description: prismicBlogItem.data.seo_description,
    keywords: prismicBlogItem.data.seo_keywords,
    no_robots: prismicBlogItem.data.no_robots,
  }
  console.log('seo: ', seo)

  const {title, date, body} = recipesItem

  const topMenu = props.data.prismicTopMenu || {}
  const footer = props.data.prismicFooter || {}

  const cntx = {title, date}

  return (
    <Layout topMenu={topMenu.data} footer={footer} seo={seo}>
      <SliceZone slices={body} components={components} context={cntx}/>
    </Layout>
  )
}

export default BlogItem

export const query = graphql`
  query($id: String!) {
    prismicBlog {
      data {
        title_section_image {
          url
        }
      }
    }
    prismicBlogItem(id: { eq: $id }) {
      id
      type
      data {
        seo_title
        seo_description
        seo_keywords
        no_robots
        type
        title
        date
        body {
          ...PrismicBlogItemBlogInfo
          ...PrismicBlogItemRecipeInfo
          ...PrismicBlogItemVideoblogInfo
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
  }
`